import React from "react"
import logo from "./climbing.svg"
import "./App.css"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Bora escalar?</p>
      </header>
    </div>
  )
}

export default App
